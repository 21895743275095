<script>
  import Base from '@backend/Base.vue';
  export default {
    extends: Base,
    data(){
        return {
            Name:"BoDashboard",
            site_name:"",
            topic:0,
            recipe:0,
            product:0,
            ask:[],
            viewedProd:[],
            viewedArticles:[],
        }
    },
    computed: {
      ufullname() {
        return this.user ? this.user.bu_full_name : "-"
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {}
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Howdy {{ufullname}}</h5>
            <br> Welcome to the <strong>{{site_name}}</strong> Website Administrator Page
            <br>You are on the PROTECTED page of the <strong>{{site_name}}</strong> Website Content Management
            system.<br><br>
            <p> This page is specifically intended ONLY for website administrators who will manage content.<br>
              Before making arrangements, there are few things to note: <br><br>

              1. Save and secure your Username and Password to avoid things that are not desirable.<br>
              2. Prepare the material or content that will be updated first.<br>
              3. Also prepare photos, videos or other related material, to facilitate the updating process.<br>
              4. If there is any questions about using features in this backoffice, you can ask the web developer contact
              below.<br>
              5. Use the latest version of the browser to get the compatibility features in this backoffice.<br>
              <p>
                Web Developer Contact :<br>
                Email : hello@lingkar9.com<br>
                Phone : +62-21-744-6929
              </p><br />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-6">
           <router-link :to="{name:'BoProduct'}">
           <div class="card bg_1 ">
              <div class="card-body">
                 <div class="d-flex flex-row">
                    <div class="round align-self-center round-info"><i class="fas fa-cube"></i></div>
                    <div class="m-l-10 align-self-center">
                       <!-- <h3 class="m-b-0">{{resData.ard_customers}}</h3> -->
                       <p>Total Products</p>
                       <h3>{{product}}</h3>
                    </div>
                 </div>
              </div>
           </div>
           </router-link>
        </div>
          <!-- Column -->
          <div class="col-lg-4 col-md-6">
            <router-link :to="{name:'BoTopic'}">
             <div class="card bg_2 mb-0">
                <div class="card-body">
                   <div class="d-flex flex-row">
                      <div class="round align-self-center round-danger"><i class="fas fa-list-alt"></i></div>
                      <div class="m-l-10 align-self-center">
                         <!-- <h3 class="m-b-0">{{resData.ard_affiliate}}</h3> -->
                        <p>Total Article</p>
                       <h3>{{topic}}</h3>
                      </div>
                   </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Column -->
          <div class="col-lg-4 col-md-6">
            <router-link :to="{name:'BoRecipe'}">
             <div class="card bg_3 mb-0">
                <div class="card-body">
                   <div class="d-flex flex-row">
                      <div class="round align-self-center round-success"><i class="fas fa-paste"></i></div>
                      <div class="m-l-10 align-self-center">
                         <!-- <h3 class="m-b-0">{{resData.ard_wineries}}</h3> -->
                          <p>Total Recipe</p>
                       <h3>{{topic}}</h3>
                      </div>
                   </div>
                 </div>
               </div>
            </router-link>
          </div>
    </div>


    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Latest Questions - Ask Doctor</h5>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Actions</th>
            </thead>
            <tbody v-if="ask.length" v-for="(a,ka) in ask" :key="ka">
              <tr>
                <td>{{ka+1}}</td>
                <td>{{a.ad_subject}}</td>
                <td>
                  <label v-if="a.ad_status=='Y'" class="label label-success">Answered</label>
                  <label v-else class="label label-danger">Not Answered</label>
                </td>
                <td>
                  <router-link class="icon_action" :to="{name:'BoAskedDoctor',params:{id:a.ad_id}}" v-tooltip="'Answered'"><i class="ti-marker-alt"></i></router-link>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr><td colspan="4" align="center">No Content</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Most Viewed Products</h5>
      </div>
      <div class="card-body">
        <div class="row" v-if="viewedProd.length">
          <div class="col" v-for="(v,k) in viewedProd" :key="k">
            <div class="item-list collection text-center d-flex flex-column h-100">
              <div class="product-img recipe-cat">
                <img :src="uploader(v.mp_image,'200')" class="w-100" :alt="v.mp_title">
              </div>
              <h5 class="card-title mt-3 mb-1 mt-auto">{{v.mp_title}}</h5>
              <strong class="badge badge-success">{{v.mp_count_link}} Views</strong>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12 text-center">No Content</div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Most Viewed Info Kesehatan</h5>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>Title</th>
              <th>Views</th>
              <th>Category</th>
              <th>Sub Category</th>
            </thead>
            <tbody v-if="viewedArticles.length">
              <tr v-for="(v1,k1) in viewedArticles" :key="k1">
                <td>{{k1+1}}</td>
                <td>{{v1.mh_title}}</td>
                <td>{{v1.mh_view_count}}</td>
                <td>{{v1.cat}}</td>
                <td>{{v1.subCat}}</td>
              </tr>
            </tbody>
            <tbody v-else> <tr><td colspan="5" align="center">No Content</td></tr></tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>